import React, { Fragment, memo, useEffect, useState } from "react";
import { useInvestorProjectsUnit } from "./hooks/useInvestorsProject";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppStore } from "../../../../redux/store";
import { getAuthToken, unsetAuth } from "../../../../helpers/authHelper";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { PieChart, Pie, Sector, ResponsiveContainer } from "recharts";
import "./scss/ProjectInvestor.scss";

import MainTemplate from "../../layout/MainLayout";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  IconButton,
  Modal,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  IconCheck,
  IconChecklist,
  IconChevronRight,
  IconDownload,
  IconEyeCheck,
  IconHistory,
  IconInfoCircle,
  IconPlus,
  IconX,
} from "@tabler/icons-react";
import { formatDistanceToNow, isValid, parse } from "date-fns";
import { es } from "date-fns/locale";
import useCallApiAndLoad from "../../../../hooks/useCallApiAndLoad";
import { getDataProjects } from "../../services/devsServices";
import { IProject } from "../../models/Devs";
import { fetchResponseAdapter } from "../../../../adapters/fetchAdapter";
import { logoutAction } from "../../../../redux/features/auth";

const ProjectInvestors = memo(() => {
  const { country } = useSelector((store: AppStore) => store.country);
  const { id = "" } = useParams();
  const [openDocuments, setOpenDocuments] = useState(false);
  const [openRules, setOpenRules] = useState(false);
  const [data, setData] = useState<Array<IProject>>([]);
  const { callEndpoint } = useCallApiAndLoad();
  const user = useSelector((store: AppStore) => store.auth);
  const [open, setOpen] = useState(false);
  const [openHistory, setOpenHistory] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentDocumentPath, setCurrentDocumentPath] = useState("");
  const token = getAuthToken(user);
  const dev = useSelector((store: AppStore) => store.authDeveloper);
  const [investorDoc, setInvestorDoc] = useState([]);

  const [selectedScoreData, setSelectedScoreData] = useState<any>({});

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    detailProject,
    loading,
    getDataInvestirProjectBank,
    getDataDetailModel,
    investorBank,
  } = useInvestorProjectsUnit();

  useEffect(() => {
    getDataDetailModel(token, id);
    getDataInvestirProjectBank(token, id);
  }, []);

  const renderStatus = (param: string) => {
    switch (param) {
      case "available":
        return (
          <span className="bg-[#D5F0E3] text-primary px-2 py-1 text-xs font-medium rounded-full">
            Disponible
          </span>
        );
      case "prebooked":
        return (
          <span className="bg-[#FEE3D5] text-orange px-2 py-1 text-xs font-medium rounded-full">
            Pre-reservado
          </span>
        );
      case "taken":
        return (
          <span className="bg-[#DDDEE4] text-blueDark px-2 py-1 text-xs font-medium rounded-full">
            Reservada
          </span>
        );
      case "assigned":
        return (
          <span className="bg-blue700 px-3 py-2 text-xs font-medium text-white rounded-full">
            Asignada
          </span>
        );
      case "signing_promise":
        return (
          <span className="bg-[#212121] px-2 py-1 text-xs font-medium text-white rounded-full">
              Firma de promesa
          </span>
          );
          case "on_deed":
        return (
          <span className="bg-[#ACE2C7] px-2 py-1 text-xs font-medium text-[#212121] rounded-full">
              Escrituración
          </span>
          );
          case "sold_external":
        return (
          <span className="bg-[#8F93B4] px-2 py-1 text-xs font-medium text-white rounded-full">
              Venta externa
          </span>
          );
      default:
        return "";
    }
  };

  const renderScoreColumn = (params: any) => {
    const score = params.value?.color;
    let scoreColor = "";
    let scoreText = "";

    // Define los colores y el texto dependiendo del valor de score
    if (score === "GREEN") {
      scoreColor = "green";
      scoreText = "Alto";
    } else if (score === "YELLOW") {
      scoreColor = "yellow";
      scoreText = "Medio";
    } else if (score === "GRAY") {
      scoreColor = "#e0e0e0";
      scoreText = 'No disponible';
    }else {
      scoreColor = "red";
      scoreText = "Bajo";
    }

    // Renderiza el termómetro visual con el color y el texto correspondiente
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          style={{
            width: 20,
            height: 20,
            borderRadius: "50%",
            backgroundColor: scoreColor,
            marginRight: 5,
          }}
        />
        <span>{scoreText}</span>
      </div>
    );
  };
  const handleOpenDialog = () => {
    setOpenDocuments(true);
  };

  const columns: GridColDef[] = [
    {
      field: "investor_name",
      headerName: "Propietario",
      flex: 1,
      headerClassName: "test-colum",
      headerAlign: "center",
      cellClassName: "test-row",
      align: "center",
      renderCell: (params) => (<>
       
       <p className=" blur-sm">{
          params.value 
        }</p> 
      </>)
    },
    {
      field: "unit_name",
      headerName: "Unidad",
      flex: 1,
      headerClassName: "test-colum",
      headerAlign: "center",
      cellClassName: "test-row",
      align: "center",
    },
    {
      field: "debt_level",
      headerName: "% Endeudamiento",
      flex: 1,
      headerClassName: "test-colum",
      headerAlign: "center",
      cellClassName: "test-row",
      align: "center",
    },
    {
      field: "debt",
      headerName: "Deuda",
      flex: 1,
      headerClassName: "test-colum",
      headerAlign: "center",
      cellClassName: "test-row",
      align: "center",
      renderCell: (params) => (
        <>
          {params.value !== "" && params.value !== null ? (
            <p>${params?.value}</p>
          ) : (
            <p>No disponible</p>
          )}
        </>
      ),
    },
    {
      field: "score_detail",
      headerName: "Score",
      type: "number",
      flex: 1,
      headerClassName: "test-colum",
      headerAlign: "center",
      cellClassName: "test-row",
      align: "center",
      renderCell: (params) => (
        <button
          className="w-full h-full flex justify-between items-center"
          onClick={() => {
            setSelectedScoreData(params.row);
            setOpenRules(true);
          }}
        >
          {renderScoreColumn(params)}
          <Tooltip
            title={
              <Fragment>
                <h2 className="text-lg font-semibold">Ratio de liquidez</h2>
                <p className="text-sm">{params?.value?.description}</p>
                <p className="text-lg font-semibold text-center mt-2">
                  Ratio {params.value?.score_detail}
                </p>
              </Fragment>
            }
          >
            <IconButton>
              <IconInfoCircle />
            </IconButton>
          </Tooltip>
        </button>
      ),
    },
    {
      field: "documents",
      headerName: "Documentos",
      flex: 1,
      headerClassName: "test-colum",
      headerAlign: "center",
      cellClassName: "test-row",
      align: "center",
      renderCell: (params) => (
        <>
        {
          params?.value?.length > 0 ? <Button
          onClick={() => {
            setInvestorDoc(params?.value);
            handleOpenDialog();
          }}
        >
          <IconChecklist color="#212121" />
        </Button> : <p>No disponible</p>
        }
        </>
        
      ),
    },
  ];

  const investorBankWithIds = investorBank?.map(
    (investor: any, index: number) => ({
      ...investor,
      id: index + 1, // Utilizando el índice + 1 como ejemplo de identificador único
    })
  );


  // const updateDateTime = (updateDate: string) => {
  //   const parsedDate = parse(updateDate, "dd/MM/yyyy", new Date());
  //   if (isValid(parsedDate)) {
  //     return (
  //       <p className="text-sm font-bold text-primary flex justify-start items-center">
  //         {" "}
  //         Actualizacíon{" "}
  //         {formatDistanceToNow(parsedDate, {
  //           addSuffix: true,
  //           locale: es,
  //         })}
  //       </p>
  //     );
  //   }
  // };

  const handleOpen = (documentPath: string) => {
    setCurrentDocumentPath(documentPath);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentDocumentPath("");
  };
  const handleOpenHistory = () => {
    setOpenHistory(true);
  };

  const handleCloseHistory = () => {
    setOpenHistory(false);
  };

  const downloadFile = async (documentPath: string) => {
    const response = await fetch(documentPath);
    const filenameSplit = documentPath.split("/");
    const fileName = filenameSplit[filenameSplit.length - 1];
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  };

  useEffect(() => {
    const loadPayments = async () => {
      setIsLoading(true);
      // TODO add new fields from backend and new table
      const { status, data } = await callEndpoint(getDataProjects(token));
      if (status === 401) {
        dispatch(logoutAction({}));
        unsetAuth();
        navigate(`/app/${country}/auth/desarrolladoras/login`);
      }
      if (status === 200) {
        setIsLoading(false);
        const response = fetchResponseAdapter(data);
        // setPayments(response.data.items || [])
        setData(response.data.items);
      }
    };
    loadPayments().catch((e) => console.error(e));
  }, []);

  const goToDetail = (id: string) => {
    navigate(`/app/${country}/desarrolladoras/propietarios/proyectos/${id}`);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    window.location.reload();
  };

  // Grafica

  const getColorData = (data: any) => {
    // Definimos un tipo específico para las claves de color
    const colorCounts: { GREEN: number; YELLOW: number; RED: number } = {
      GREEN: 0,
      YELLOW: 0,
      RED: 0,
    };

    data.forEach((item: any) => {
      const color = item.score_detail.color as "GREEN" | "YELLOW" | "RED"; // Especificamos el tipo exacto del color
      if (colorCounts[color] !== undefined) {
        colorCounts[color]++;
      }
    });

    const total = colorCounts.GREEN + colorCounts.YELLOW + colorCounts.RED;

    return [
      {
        name: "Alto",
        value: colorCounts.GREEN,
        percent: (colorCounts.GREEN / total) * 100,
        fill: "#2FB672",
      },
      {
        name: "Medio",
        value: colorCounts.YELLOW,
        percent: (colorCounts.YELLOW / total) * 100,
        fill: "#FFEB3B",
      },
      {
        name: "Bajo",
        value: colorCounts.RED,
        percent: (colorCounts.RED / total) * 100,
        fill: "#F44336",
      },
    ];
  };

  const renderActiveShape = (props: any) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      payload,
      value,
      percent,
      fill,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";
    console.log(percent)
    return (
      <g>
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
          {payload.name}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke={fill}
          fill="none"
        />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 6}
          y={ey}
          textAnchor={textAnchor}
          fill="#333"
        >{`${payload.name}`}</text>
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          dy={18}
          textAnchor={textAnchor}
          fill="#999"
          className="text-xs"
        >
          {`(${(percent).toFixed(2)}%)`}
        </text>
      </g>
    );
  };

  const InvestorPieChart = ({ investorData }: any) => {
    const [activeIndex, setActiveIndex] = useState(0);

    const colorData = getColorData(investorData);
    console.log(colorData); // Verifica que los datos estén correctos

    const onPieEnter = (_: any, index: any) => {
      setActiveIndex(index);
    };

    return (
      <div className="w-full py-4 bg-white rounded-xl" style={{ height: 400 }}>
        <ResponsiveContainer width="100%" height="100%">
          <PieChart>
            <Pie
              activeIndex={activeIndex}
              activeShape={renderActiveShape}
              data={colorData}
              cx="50%"
              cy="50%"
              innerRadius={60}
              outerRadius={80}
              dataKey="value"
              onMouseEnter={onPieEnter}
            />
          </PieChart>
        </ResponsiveContainer>
      </div>
    );
  };

  console.log(investorBank);

  return (
    <MainTemplate>
      <Dialog open={openDocuments} onClose={() => setOpenDocuments(false)}>
        <div className="w-full lg:w-[600px] px-6 py-6">
          <h1 className="font-semibold text-[#212121] text-2xl">
            Documentación
          </h1>
          <p className="text-base font-normal text-[#757575]">
            Detalle de documentos necesarios del propietario
          </p>
          <div className="w-full">
            {investorDoc?.length > 0 &&
              investorDoc?.map((document: any, index: number) => (
                <div
                  key={index}
                  className="w-full flex justify-between items-center border border-gray-300 rounded-lg my-2 py-2 px-2 gap-3"
                >
                  <div className="flex justify-start items-center gap-2 w-3/5">
                    <div className="w-full">
                      <p className="text-sm font-bold text-[#212121] truncate">
                        {document?.type}
                      </p>
                      {document.number !== "NA" && (
                        <p className="text-xs font-normal text-[#757575]">
                          #{document.value}
                        </p>
                      )}

                      {/* {document?.re_entry_at &&
                        updateDateTime(document?.re_entry_at as string)} */}
                    </div>
                  </div>

                  <div className=" flex w-2/5 justify-end gap-3 items-center">
                    {document?.url !== null ? (
                      <button
                        className="focus:outline-none border-none tooltipDev"
                        onClick={() => handleOpen(document?.url)}
                      >
                        <IconEyeCheck color="#212121" />
                        <span className="tooltiptext">Ver documento</span>
                      </button>
                    ) : (
                      <></>
                    )}
                    {document?.url !== null ? (
                      <button
                        className="focus:outline-none border-none tooltipDev"
                        onClick={() => downloadFile(document.url)}
                      >
                        <IconDownload color="#212121" />
                        <span className="tooltiptext">Descargar</span>
                      </button>
                    ) : (
                      <></>
                    )}

                    {/* <button
                      onClick={() => handleOpenHistory()}
                      className="focus:outline-none border-none tooltipDev"
                    >
                      <IconHistory color="#212121" />
                      <span className="tooltiptext">Historial</span>
                    </button> */}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={openRules}
        onClose={() => setOpenRules(false)}
      >
        <div className="w-full px-6 py-6">
          <h2 className="text-2xl font-semibold text-[#212121]">
            Reglas bancarias
          </h2>
          <p className="text-sm font-normal text-[#616161]">
            A continuación, se presentan las reglas bancarias que el usuario ha
            cumplido y aquellas que requieren atención, para asegurar su
            cumplimiento financiero y una relación segura con la entidad.
          </p>
          <div className="w-full mt-6">
            {selectedScoreData &&
              selectedScoreData?.rules?.map((item: any, index: number) => (
                <>
                  <Accordion>
                    <AccordionSummary
                      className="w-full"
                      expandIcon={<IconPlus />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <h1 className="text-xl font-semibold text-[#212121] capitalize">
                        {item?.entity}
                      </h1>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="w-full grid grid-cols-2 gap-4">
                        {item?.rules?.map((request: any, index: number) => (
                          <div className="w-full py-2">
                            <h4 className="font-medium text-[#212121] text-sm inline-block">
                              {request?.key}
                              {request?.is_boolean_value ? (
                                <IconCheck
                                  className="inline-block ml-1"
                                  color="#2FB672"
                                />
                              ) : (
                                <IconX
                                  className="inline-block ml-1"
                                  color="#f44336"
                                />
                              )}
                            </h4>
                            <p className="font-normal text-[#616161] text-sm">
                              {request?.value}
                            </p>
                          </div>
                        ))}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </>
              ))}
          </div>
        </div>
      </Dialog>
      <div className="w-full">
        <div className="container mx-auto">
          <div className="py-24">
            <div className=" flex flex-wrap lg:flex-nowrap gap-6 px-4 lg:px-0">
              <div className="w-full lg:w-2/6">
                <div className="info-card sticky top-0 flex flex-col overflow-hidden rounded-lg shadow-at-8 w-full">
                  <div className="relative h-full">
                    <div className="rounded-xl py-5">
                      <div className="grid grid-cols-3 gap-3 mt-2">
                        {data?.length >= 0 ? (
                          data?.map((item: IProject) => {
                            return (
                              <div
                                className={`border-2 ${
                                  id === item?.id
                                    ? "border-primary"
                                    : "border-gray-200"
                                } hover:border-primary hover:duration-200  p-1 rounded-2xl flex justify-center items-center`}
                              >
                                <button
                                  onClick={() => goToDetail(item?.id)}
                                  className="w-full h-28 rounded-xl bg-center bg-no-repeat bg-cover relative"
                                  style={{
                                    backgroundImage: `url(${item?.cover})`,
                                  }}
                                >
                                  <div
                                    className="h-full w-full flex justify-start items-end px-2 py-2 absolute top-0 rounded-xl px"
                                    style={{
                                      backgroundColor: `rgba(0,0,0,0.3)`,
                                    }}
                                  >
                                    <p className=" text-white font-medium text-left leading-tight">
                                      {item?.name}
                                    </p>
                                  </div>
                                </button>
                              </div>
                            );
                          })
                        ) : (
                          <> No hay disponibles</>
                        )}
                      </div>
                    </div>
                  </div>
                  <h2 className="text-[#212121] font-semibold text-2xl mt-4 mb-2">
                      Salud de Cartera
                  </h2>

                  <InvestorPieChart investorData={investorBank} />
                </div>
              </div>
              <div className="w-full lg:w-4/6">
                <h2 className="text-[#212121] font-semibold text-2xl">
                  Detalle de proyecto
                </h2>
                <div className="py-4 rounded-lg mb-4">
                  {!loading && (
                    <div className="w-full flex flex-wrap items-center">
                      <div className="w-full lg:w-1/4">
                        <img
                          className=" object-cover object-center h-[200px] w-full rounded-lg"
                          src={detailProject?.cover}
                          alt=""
                        />
                      </div>
                      <div className="w-full lg:w-3/4 md:px-4">
                        <div className="lg:px-4 mt-4 lg:mt-0">
                          <p className=" font-normal text-sm lg:text-base text-[#757575]">
                            #{detailProject?.code}
                          </p>
                          <div className="w-full text-blackGrayScale text-2xl lg:text-3xl font-semibold text-left tracking-wide">
                            {!loading && detailProject?.name}
                          </div>
                          <p className="text-[#757575] text-base md:text-lg">
                            {detailProject?.address}
                          </p>
                        </div>
                        <div className="flex flex-wrap mt-4 gap-4">
                          <div className="py-1 px-4 border border-[#E0E0E0] rounded-full">
                            <p className="text-sm font-bold">
                              Unidades totales
                            </p>
                            <p className="text-xs text-left text-[#757575]">
                              {detailProject?.total_units}
                            </p>
                          </div>
                          <div className="py-1 px-4 border border-[#E0E0E0] rounded-full">
                            <p className="text-sm font-bold">
                              Fecha estimada de entrega
                            </p>
                            <p className="text-xs text-left text-[#757575]">
                              {detailProject?.estimate_built_at}
                            </p>
                          </div>
                          <div className="py-1 px-4 border border-[#E0E0E0] rounded-full">
                            <p className="text-sm font-bold">Disponible</p>
                            <p className="text-xs text-left text-[#757575]">
                              {detailProject?.total_available_units}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="w-full lg:w-auto">
                  <h1 className="w-full mt-6 text-blackGrayScale text-2xl lg:text-2xl font-semibold text-left tracking-wide">
                    Datos de propietarios
                  </h1>
                  <p className=" text-lg font-normal text-gray600 mb-2">
                    Ve la información necesaria sobre los propietarios
                  </p>
                </div>
                <div className="w-full">
                  <div style={{ height: 768, width: "100%" }}>
                    <DataGrid
                      rows={investorBankWithIds}
                      columns={columns}
                      loading={loading}
                      autoPageSize
                      getRowId={(row) => row.id} // Definiendo una función para obtener el identificador único de cada fila
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="document-modal-title"
        aria-describedby="document-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "40%",
            bgcolor: "background.paper",
            p: 4,
            borderRadius: "10px",
            height: "50vh",
            overflowY: "auto",
            "@media (max-width: 780px)": {
              width: "90%",
              height: "70vh",
            },
          }}
        >
          <iframe
            src={currentDocumentPath}
            width="100%"
            height="100%"
            title="Document Viewer"
          />
        </Box>
      </Modal>
      <Modal
        open={openHistory}
        onClose={handleCloseHistory}
        aria-labelledby="document-modal-title"
        aria-describedby="document-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "40%",
            bgcolor: "background.paper",
            p: 4,
            borderRadius: "10px",
          }}
        >
          <div className="w-full">
            <h1 className="font-semibold text-[#212121] text-xl mb-4">
              Historial
            </h1>
            <p className="text-sm inline-block text-[#757575] my-1">
              <IconChevronRight className=" inline-block" /> El documento fue
              actualizado aproximadamente hace 4 meses
            </p>
            <p className="text-sm inline-block text-[#757575] my-1">
              <IconChevronRight className=" inline-block" /> El document se
              actualiara dentro de 2 meses
            </p>
          </div>
        </Box>
      </Modal>
    </MainTemplate>
  );
});

ProjectInvestors.displayName = "ProjectInvestors";
export default ProjectInvestors;
