import { useEffect, useState } from "react";
import { isLoggedIn } from "../../helpers/authHelper";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AppStore } from "../../redux/store";
import NavBarLandlord from "./NavBarLandlord";
import NavBarTenant from "./NavBarTenant";

import closeMenu from "../../assets/icons/close-modal-black.svg";
import { toogleAction } from "../../redux/features/navigation";

import ItemNavMobile from "./components/ItemNavMobile/ItemNavMobile"
import { setCountryAction } from "../../redux/features/country";
import { setCountry } from "../../helpers/countryHelper";

type IModalProps = {
  onAction: React.MouseEventHandler<HTMLButtonElement> | undefined;
};

const logo = 'https://storage.googleapis.com/assets-us-east4-propilatam/website-assets/propi-web/v2/logo-propi.svg'

const MobileMenu: React.FC<IModalProps> = (props) => {
  const { country } = useSelector((store: AppStore) => store.country);
  const [isOpenOptions, setIsOpenOptions] = useState<boolean>(false);
  const [selectedCountry, setSelectedCountry] = useState<string>(country);
  const { visible: showNav } = useSelector(
    (store: AppStore) => store.navigation
  );
  const { id = "", code } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((store: AppStore) => store.auth);
  const isLogged = isLoggedIn(user);

  useEffect(() => {
    document.body.style.overflowY = "hidden";
    if (isLogged) {
      dispatch(toogleAction(true));
    } else {
      dispatch(toogleAction(false));
    }
  }, []);

  const doShowNav = () => {
    if (!isLogged) {
      document.body.style.overflowY = "scroll";
      return navigate(`/app/${country}/auth/login`, { replace: true });
      
    }
  };

  const doRegister = () => {
    document.body.style.overflowY = "scroll";
    navigate(`/app/${country}/auth/registration`, { replace: true })
  }

  const getNavBar = () => {
    if (!showNav || !isLoggedIn) {
      return null;
    }
    switch (user.realm) {
      case 'landlord':
        return <NavBarLandlord />
      case 'tenant':
        return <NavBarTenant />
    }
  };

  const handleDropdownClick = () => {
    setIsOpenOptions(!isOpenOptions);
  };

  const handleCountrySelect = (option: string) => {
    const parts = location.pathname.split("/");
    parts[2] = option;
    const newPath = parts.join("/");
    const searchParams = new URLSearchParams(location.search);

    const newUrl = newPath + '?' + searchParams.toString();

    setSelectedCountry(option);
    dispatch(setCountryAction({ country: option }));
    setCountry(option);
    setIsOpenOptions(false)

    return window.open(newUrl, "_self");
    
  };

  const OptionComprar: any = [
    {
      name: "En Planos",
      link: `/${country}/venta/casas-y-apartamentos/proyectos`,
    },
    {
      name: "Casas",
      link: `/${country}/bienes-raices/comprar?type=house`,
    },
    {
      name: "Apartamentos",
      link: `/${country}/bienes-raices/comprar?type=apto`,
    },
    {
      name: "Terrenos",
      link: `/${country}/bienes-raices/comprar?type=land`
    }
  ];

  const OptionComprarGt: any = [
    {
      name: "En Planos",
      link: `/${country}/venta/casas-y-apartamentos/proyectos`,
    },
    {
      name: "Casas",
      link: `/${country}/bienes-raices/comprar?type=house`,
    },
    {
      name: "Apartamentos",
      link: `/${country}/bienes-raices/comprar?type=apto`,
    },
  ];

  const OptionPropietario: any = [{
    name: 'Vender mi propiedad',
    link: `/${country}/venta/vender-casa-apartamento-con-propi`
  }, {
    name: "Rentar mi propiedad",
    link: `/${country}/alquiler/alquila-tu-casa-apartamento-con-propi`,
  },]

  const OptionCalcula: any = [
    {
      name: "Calculadora de crédito hipotecario",
      link: `/${country}/venta/calculadora-credito-hipotecario`,
    },
    {
      name: "Calculadora de renta",
      link: `/${country}/alquila-casa-apartamento-en-propi#calculadora`,
    },
  ];

  const OptionPropi: any = [
    {
      name: "Ayuda",
      link: `/${country}/ayuda`,
    },
    {
      name: "¿Quienes somos?",
      link: `/${country}/quienes-somos`,
    },
    {
      name: "Refiere y gana",
      link: `/app/${country}/referidos`,
    },
    {
      name: "Blog",
      link: `https://blog.propilatam.com/`,
    },
  ];
  return (
    <>
      <div className=" w-full h-full bg-white  ">
        <div className="container flex flex-wrap justify-between px-4 py-3 items-center mx-auto pb-1">
          <a href="/" className="flex items-center">
            <img src={logo} className="mr-3 h-6 sm:h-9" alt="Logo" />
          </a>

          <div
            className="flex
           lg:hidden justify-end items-center"
          >
            <button onClick={props.onAction}>
              <img src={closeMenu} alt="" />
            </button>
          </div>
        </div>
        <div className="container px-6 pt-6">
          <ul className="">
            <li className="py-3 w-full">
              <ItemNavMobile title="Comprar">
                {
                  country !== "gt" ? <ul className="flex flex-col">
                  {OptionComprar.length > 0 &&
                    OptionComprar?.map((item: any, index: number) => (
                      <a
                        className="text-[#212121] text-sm mt-2 xl:text-base"
                        href={item?.link}
                      >
                        {item?.name}
                      </a>
                    ))}
                </ul> : <ul className="flex flex-col">
                  {OptionComprarGt.length > 0 &&
                    OptionComprarGt?.map((item: any, index: number) => (
                      <a
                        className="text-[#212121] text-sm mt-2 xl:text-base"
                        href={item?.link}
                      >
                        {item?.name}
                      </a>
                    ))}
                </ul> 
                }
              </ItemNavMobile>
            </li>
            <li className="flex items-center py-3">
              <a
                href={`/${country}/bienes-raices/alquilar`}
                className="block py-1 text-blackScale font-normal text-base"
              >
                Alquilar
              </a>
            </li>
            <li className="py-3 w-full">
              <ItemNavMobile title="Propietarios">
                <ul className="flex flex-col">
                  {OptionPropietario.length > 0 &&
                    OptionPropietario?.map((item: any, index: number) => (
                      <a
                        className="text-[#212121] text-sm mt-2 text-base"
                        href={item?.link}
                      >
                        {item?.name}
                      </a>
                    ))}
                </ul>
              </ItemNavMobile>
            </li>
            <li className="flex items-center py-3">
              <ItemNavMobile title="Simula y Calcula">
                <ul className="flex flex-col">
                  {OptionCalcula.length > 0 &&
                    OptionCalcula?.map((item: any, index: number) => (
                      <a
                        className="text-[#212121] text-sm mt-2 text-base"
                        href={item?.link}
                      >
                        {item?.name}
                      </a>
                    ))}
                </ul>
              </ItemNavMobile>
            </li>
            <li className="py-3 w-full">
              <ItemNavMobile title="Más de Propi">
                <ul className="flex flex-col">
                  {OptionPropi.length > 0 &&
                    OptionPropi?.map((item: any, index: number) => (
                      <a
                        className="text-[#212121] text-sm mt-2 text-base"
                        href={item?.link}
                      >
                        {item?.name}
                      </a>
                    ))}
                </ul>
              </ItemNavMobile>
            </li>
            <li className="py-3 w-full flex justify-start items-center">
              <p className="text-[#212121] font-medium mr-2">Pais</p>
              {!id && !code && (
              <div className="dropdown-country mr-2">
                <div
                  className={`selected-country  "border-[#e0e0e0] border`}
                  onClick={handleDropdownClick}
                >
                  {selectedCountry === "sv" && (
                    <img
                      className="w-8"
                      src="https://storage.googleapis.com/assets-us-east4-propilatam-dev/website-assets/propi-web/el-salvador.svg"
                      alt="sv"
                    />
                  )}
                  {selectedCountry === "gt" && (
                    <img
                      className="w-8"
                      src="https://storage.googleapis.com/assets-us-east4-propilatam-dev/website-assets/propi-web/guatemala.svg"
                      alt="sv"
                    />
                  )}
                  <i
                    className={`gg-chevron-down text-[#212121]`}
                  ></i>
                </div>
              {isOpenOptions && (
                  <ul className="options-country">
                    <li
                      className={`country-option ${
                        selectedCountry === "sv" ? "selected" : ""
                      }`}
                      onClick={() => {
                        handleCountrySelect("sv");
                      }}
                    >
                      <img
                        className="w-8"
                        src="https://storage.googleapis.com/assets-us-east4-propilatam-dev/website-assets/propi-web/el-salvador.svg"
                        alt="sv"
                      />
                      <p>SV</p>
                    </li>
                    <li
                      className={`country-option ${
                        selectedCountry === "gt" ? "selected" : ""
                      }`}
                      onClick={() => {
                        handleCountrySelect("gt");
                      }}
                    >
                      <img
                        className="w-8"
                        src="https://storage.googleapis.com/assets-us-east4-propilatam-dev/website-assets/propi-web/guatemala.svg"
                        alt=""
                      />
                      <p>GT</p>
                    </li>
                  </ul>
                )}
                </div>
                ) }
            </li>
  
            {/* <li className="py-3 w-full flex items-center">
              <p className="block py-1 text-blackScale font-normal text-base mr-2">
                Pais
              </p>
              <div className="dropdown-country mr-2">
              <div
                className={`selected-country border-gray-200 border`}
                onClick={handleDropdownClick}
              >
                {selectedCountry === "sv" && (
                  <img
                    className="w-8"
                    src="https://storage.googleapis.com/assets-us-east4-propilatam-dev/website-assets/propi-web/el-salvador.svg"
                    alt="sv"
                  />
                )}
                {selectedCountry === "gt" && (
                  <img
                    className="w-8"
                    src="https://storage.googleapis.com/assets-us-east4-propilatam-dev/website-assets/propi-web/guatemala.svg"
                    alt="sv"
                  />
                )}
                <i className={`gg-chevron-down text-gray-200`}></i>
              </div>
              {isOpenOptions && (
                <ul className="options-country">
                  <li
                    className={`country-option ${
                      selectedCountry === "sv" ? "selected" : ""
                    }`}
                    onClick={() => {
                      handleCountrySelect("sv");
                    }}
                  >
                    <img
                      className="w-8"
                      src="https://storage.googleapis.com/assets-us-east4-propilatam-dev/website-assets/propi-web/el-salvador.svg"
                      alt="sv"
                    />
                    <p>SV</p>
                  </li>
                  <li
                    className={`country-option ${
                      selectedCountry === "gt" ? "selected" : ""
                    }`}
                    onClick={() => {
                      handleCountrySelect("gt");
                    }}
                  >
                    <img
                      className="w-8"
                      src="https://storage.googleapis.com/assets-us-east4-propilatam-dev/website-assets/propi-web/guatemala.svg"
                      alt=""
                    />
                    <p>GT</p>
                  </li>
                </ul>
              )}
            </div>
            </li> */}
            {/* <li className="py-3 w-full">
              <a href="#" className="block py-1 text-blackScale font-normal text-base ">
                Soy inquilino
              </a>
            </li>
            <li className="py-3 w-full">
              <a href="#" className="block py-1 text-blackScale font-normal text-base ">
                Soy propietario
              </a>
            </li>
            <li className="py-3 w-full">
              <a href="#" className="block py-1 text-blackScale font-normal text-base ">
                Quiero invertir
              </a>
            </li> */}
          </ul>
        </div>
        {/* <div className="container px-6 py-4 h-full">
          <div className="flex flex-wrap w-full">
            {
              isLogged && <h3 className="text-primary font-semibold text-lg">Mi cuenta</h3>
            }

            <div className="w-full">{getNavBar()}</div>
            </div>
            <div className="mt-10">
            <button
              onClick={doShowNav}
              type="button"
              className={
                isLogged
                  ? "py-2 text-primary w-full text-left"
                  : "text-white w-full  bg-blackScale hover:bg-primary focus:ring-4 focus:outline-none focus:ring-blue-300 font-normal rounded-lg text-base px-5 py-2.5 text-center mr-3 md:mr-0 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              }
            >
              {isLogged ? "" : "Iniciar sesión"}
            </button>
            {
              !isLogged &&  <button
              onClick={doRegister}
              type="button"
              className="mt-3 text-blackScale w-full bg-white border-blackScale border hover:bg-primary hover:opacity-80 focus:ring-4 focus:outline-none focus:ring-blue-300 font-normal rounded-lg text-base px-5 py-2.5 text-center mr-3 md:mr-0 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Registrarme
            </button>
            }
          </div>
        </div> */}
      </div>
    </>
  );
};

export default MobileMenu;
