import { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { PatternFormat } from "react-number-format";
import { Player } from "@lottiefiles/react-lottie-player";
import Cards from 'react-credit-cards-2';
import 'react-credit-cards-2/dist/es/styles-compiled.css';


import {
  IconCreditCard,
  IconUser,
  IconCalendarMonth,
  IconCalendar,
  IconLock,
} from "@tabler/icons-react";

import { Controller, useForm } from "react-hook-form";
import FormControlError from "../Controls/FormControlError";
import {
  onePayEndpoint,
  onePaySalesEndpoint,
} from "../../pages/Tenant/services/tenantService";
import { useSelector } from "react-redux";
import { AppStore } from "../../redux/store";
import { getAuthToken } from "../../helpers/authHelper";
import { fetchResponseAdapter } from "../../adapters/fetchAdapter";
import { OnePayPayload } from "../../models/paymentModel";
import { onePayInvestorEndpoint } from "../../pages/Investor/services/investorService";
import useCallApiAndLoad from "../../hooks/useCallApiAndLoad";

import "./scss/ModalPayment.scss";
import ErrorForm from "../Controls/ErrorForm";

interface PaymentForm {
  name_card: string;
  lastname_card: string;
  number_card: string;
  month: string;
  year: string;
  cvv: string;
}

interface Props {
  name?: string;
  btnText?: string;
  onAction: React.MouseEventHandler<HTMLButtonElement> | undefined;
  extraAction: () => void;
  contractId: string;
  transactionId: string;
  realm: string;
}

const ModalPayment = (props: Props) => {
  const { country } = useSelector((store: AppStore) => store.country);
  const { ipClient } = useSelector((store: AppStore) => store.ipClient);
  const [sendButton, setSendButton] = useState<boolean>(false);
  const [formError, setFormError] = useState("");
  const { callEndpoint } = useCallApiAndLoad();
  const user = useSelector((store: AppStore) => store.auth);
  const token = getAuthToken(user);
  const navigate = useNavigate();
  const [loadingPay, setLoadingPay] = useState<boolean>(false);
  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<PaymentForm>({
    resolver: yupResolver(
      yup
        .object({
          name_card: yup.string().required("Nombre es requerido"),
          lastname_card: yup.string().required("Apellido es requerido"),
          number_card: yup
            .string()
            .required("Numero de la tarjeta es requerido"),
          month: yup
            .string()
            .required("El mes es requerido")
            .test(
              "is-future-month",
              "El mes no puede ser anterior al actual",
              function (value) {
                if (value === undefined || value === null) {
                  return false;
                }

                const monthValue = parseInt(value, 10);

                // Si el año es el año actual, verifica que el mes no sea anterior al mes actual
                if (currentYear === parseInt(this.parent.year, 10)) {
                  return monthValue >= currentMonth;
                }

                return true; // Si el año no es el actual, no realizamos la validación del mes
              }
            ),
          year: yup
            .string()
            .required("El año es requerido")
            .test(
              "is-future-year",
              "El año no puede ser anterior al actual",
              function (value) {
                if (value === undefined || value === null) {
                  return false;
                }

                const yearValue = parseInt(value, 10);

                // Verifica que el año no sea anterior al año actual
                return yearValue >= currentYear;
              }
            ),
          cvv: yup.string().required("El cvv es requerido"),
        })
        .required()
    ),
  });


  const sendData = async (form: PaymentForm) => {
    setFormError("");
    const payload: OnePayPayload = {
      cardInfo: {
        cardNumber: form.number_card,
        expireMonth: form.month,
        expireYear: form.year,
        cvv: form.cvv,
        cardOwnerName: form.name_card,
        cardOwnerLastName: form.lastname_card,
      },
      clientIp: ipClient,
    };
    setSendButton(true);
    if (props.realm === "tenant") {
      return sendTenantPayment(payload);
    }
    if (props.realm === "landlord") {
      return sendLandlordPayment(payload);
    }

    if (props.realm === "sales") {
      return sendSalesPayment(payload);
    }
    console.log({payload})
    setFormError(`Tipo de usuario para pago no es válido`);
  };

  const sendTenantPayment = async (payload: OnePayPayload) => {
    setLoadingPay(true);
    const { status, data } = await callEndpoint(
      onePayEndpoint(payload, token, props.contractId, props.transactionId)
    );
    if (status === 201) {
      setSendButton(false);
      document.body.style.overflowY = "scroll";
      setLoadingPay(false);
      return navigate(`/app/${country}/l/tn/payment-completed`);
    } else {
      setSendButton(false);
      setLoadingPay(false);
      document.body.style.overflowY = "scroll";
    }
    const response = fetchResponseAdapter(data);
    setFormError(`(${response.code}) ${response.message}`);
  };

  const sendLandlordPayment = async (payload: OnePayPayload) => {
    const { status, data } = await callEndpoint(
      onePayInvestorEndpoint(
        payload,
        token,
        props.contractId,
        props.transactionId
      )
    );
    if (status === 201) {
      setSendButton(false);
      document.body.style.overflowY = "scroll";
      return navigate(`/app/${country}/l/ld/payment-completed`);
    } else {
      setSendButton(false);
      document.body.style.overflowY = "scroll";
    }
    const response = fetchResponseAdapter(data);
    setFormError(`(${response.code}) ${response.message}`);
  };

  const sendSalesPayment = async (payload: OnePayPayload) => {
    const { status, data } = await callEndpoint(
      onePaySalesEndpoint(payload, token, props.contractId, props.transactionId)
    );
    if (status === 201) {
      setSendButton(false);
      document.body.style.overflowY = "scroll";
      document.documentElement.scrollTop = 0;
      return navigate(`/app/${country}/l/tn/payment-sales-completed`);
    } else {
      setSendButton(false);
      document.documentElement.scrollTop = 0;
      document.body.style.overflowY = "scroll";
    }
    const response = fetchResponseAdapter(data);
    setFormError(`(${response.code}) ${response.message}`);
  };

  return (
    <div className="w-full bg-white overflow-y-auto max-w-md relative">
      {loadingPay && (
        <div className="overlay-loading">
          <div className="bg-white py-8 px-8 rounded-lg w-full">
            <Player
              speed={1}
              autoplay
              loop
              src="https://storage.googleapis.com/assets-us-east4-propilatam-dev/website-assets/propi-web/loading-propi1-verde.json"
              style={{ height: "80px", width: "80px" }}
            />
            <h1 className="text-2xl font-semibold text-[#212121] mt-4 text-center">
              Procesando tu pago
            </h1>
            <p className="text-sm font-medium text-[#757575] text-center">
              Por favor espera unos segundos y no cierres la pantalla.
            </p>
          </div>
        </div>
      )}

      <form onSubmit={handleSubmit(sendData)}>
        <div className="w-full bg-white px-4">
          <div className="w-full py-4 ">
            <h1 className="text-[#212121] font-semibold text-xl lg:text-2xl tracking-normal">
              Datos de tarjeta
            </h1>
            <div className="mt-3">
            <Cards
                number={watch('number_card') ? watch('number_card') : ''}
                expiry={`${watch('month') ? watch('month') : ''}/${watch('year') ? watch('year') : ''}`}
                cvc={watch('cvv') ? watch('cvv') : ''}
                name={`${watch('name_card') ? watch('name_card') : ''} ${watch('lastname_card') ? watch('lastname_card') : ''}`}
              />
            </div>
            {formError && <FormControlError message={formError} />}
          </div>
          <div className="my-2">
            <label className="text-[#212121] font-semibold text-sm">
              Nombre del tarjetahabiente
            </label>
            <div className="relative input-icon">
              <IconUser color="#BDBDBD" />
              <input
                {...register("name_card")}
                type="text"
                placeholder="Ej: Juan"
                className="w-full focus:outline-none mt-1 border border-grayForm rounded-md text-graySoft py-2 px-2 pl-10 "
              />
            </div>

            <ErrorForm
              message={errors?.name_card?.message}
              additionalClass="mb-3"
            />
          </div>

          <div className="my-2">
            <label className="text-[#212121] font-semibold text-sm">
              Apellido del tarjetahabiente
            </label>
            <div className="relative input-icon">
              <IconUser color="#BDBDBD" />
              <input
                {...register("lastname_card")}
                type="text"
                placeholder="Ej: Perez"
                className="w-full focus:outline-none mt-1 border border-grayForm rounded-md text-graySoft py-2 px-2 pl-10 "
              />
            </div>

            <ErrorForm
              message={errors?.lastname_card?.message}
              additionalClass="mb-3"
            />
          </div>
          <div className="my-2">
            <label className="text-[#212121] font-semibold text-sm">
              Número de la tarjeta
            </label>
            <div className="relative input-icon">
              <IconCreditCard color="#bdbdbd" />
              <Controller
                control={control}
                name="number_card"
                render={({ field: { onChange, value, onBlur } }) => (
                  <PatternFormat
                    placeholder="000-000-000-000"
                    onValueChange={(v) => onChange(v.value)}
                    className="w-full focus:outline-none mt-1 border border-grayForm rounded-md text-graySoft py-2 px-2 pl-10"
                    format="#### #### #### #### ###"
                    max={19}
                    onBlur={onBlur}
                  />
                )}
              />
            </div>

            {/* <input
              {...register("number_card")}
              type="tel"
              placeholder="000-000-000-000"
              className="w-full focus:outline-none mt-1 border border-grayForm rounded-md text-graySoft py-2 px-2 "
            /> */}
            <ErrorForm
              message={errors?.number_card?.message}
              additionalClass="mb-3"
            />
          </div>
          <div className="w-full flex items-center justify-between flex-wrap">
            <div className="w-full lg:w-1/3 px-1 my-1 lg:my-0">
              <label className="text-[#212121] font-semibold text-sm">
                Mes
              </label>
              <div className="relative input-icon">
                <IconCalendarMonth color="#BDBDBD" />
                <Controller
                  control={control}
                  name="month"
                  render={({ field: { onChange, value, onBlur } }) => (
                    <PatternFormat
                      placeholder="Ej: 09"
                      onValueChange={(v) => onChange(v.value)}
                      className="w-full focus:outline-none mt-1 border border-grayForm rounded-md text-graySoft py-2 px-2 pl-10"
                      format="##"
                      onBlur={onBlur}
                    />
                  )}
                />
              </div>

              {/* <input
                {...register("month")}
                type="text"
                className="w-full focus:outline-none mt-1 border border-grayForm rounded-md text-graySoft py-2 px-2 "
              /> */}
            </div>
            <div className="w-full lg:w-1/3 px-1 my-1 lg:my-0">
              <label className="text-[#212121] font-semibold text-sm">
                Año
              </label>
              <div className="relative input-icon">
                <IconCalendar color="#BDBDBD" />
                <Controller
                  control={control}
                  name="year"
                  render={({ field: { onChange, value, onBlur } }) => (
                    <PatternFormat
                      placeholder="Ej: 2022"
                      onValueChange={(v) => onChange(v.value)}
                      className="w-full focus:outline-none mt-1 border border-grayForm rounded-md text-graySoft py-2 px-2 pl-10"
                      format="####"
                      onBlur={onBlur}
                    />
                  )}
                />
              </div>

              {/* <input
                {...register("year")}
                type="text"
                className="w-full focus:outline-none mt-1 border border-grayForm rounded-md text-graySoft py-2 px-2 "
              /> */}
            </div>
            <div className="w-full lg:w-1/3 px-1 my-1 lg:my-0">
              <label className="text-[#212121] font-semibold text-sm">
                CVV2
              </label>
              <div className=" relative input-icon">
                <IconLock color="#bdbdbd" />
                <Controller
                  control={control}
                  name="cvv"
                  render={({ field: { onChange, value, onBlur } }) => (
                    <PatternFormat
                      onValueChange={(v) => onChange(v.value)}
                      className="w-full focus:outline-none mt-1 border border-grayForm rounded-md text-graySoft py-2 px-2 pl-10"
                      format="####"
                      max={4}
                    />
                  )}
                />
              </div>

              {/* <input
                {...register("cvv")}
                type="text"
                className="w-full focus:outline-none mt-1 border border-grayForm rounded-md text-graySoft py-2 px-2 "
              /> */}
            </div>
            <div className="w-full">
              <ErrorForm
                message={errors?.month?.message}
                additionalClass="mb-3"
              />
              <ErrorForm
                message={errors?.year?.message}
                additionalClass="mb-3"
              />
              <ErrorForm
                message={errors?.cvv?.message}
                additionalClass="mb-3"
              />
            </div>
          </div>
        </div>
        <div className="bg-white w-full">
          <div className="w-full grid grid-cols-2 gap-4 px-4 py-4">
            <button
              type="button"
              className="text-[#212121] text-sm font-medium border border-[#212121] rounded-lg"
              onClick={props.onAction}
            >
              Cancelar
            </button>
            <button
              disabled={sendButton}
              type="submit"
              className={`${
                sendButton ? " opacity-60" : ""
              } bg-[#212121] py-3 xl:py-3 w-auto text-white text-base rounded-lg flex justify-center items-center`}
            >
              <span className="">{props.btnText}</span>
            </button>
            {/* <Link
              to={"/l/tn/payment-completed"}
              onClick={() => {
                document.body.style.overflowY = "auto";
                document.body.style.overflowX = "hidden";
                window.scrollTo({
                  top: 0,
                  left: 0,
                  behavior: "smooth",
                });
              }}
              className="bg-primary py-2 pl-8 pr-2  text-white text-base rounded-full flex justify-end items-center group"
            >
              <span className="mr-2 ml-6">{props.btnText}</span>
              <img
                className="inline group-hover:-translate-x-3 duration-300 ease-in-out"
                src={arrow_button}
                alt=""
              />
            </Link> */}
          </div>
        </div>
      </form>
    </div>
  );
};

export default ModalPayment;
